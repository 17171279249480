import { FunctionalComponent, h } from "preact";
import { view } from "z-preact-easy-state";
import state from "../store";
import qs from "query-string";
import jwtDecode from "jwt-decode";
import { useLocation } from "wouter-preact";

type User = {
  "cognito:username": string;
};

const AuthCallback: FunctionalComponent = view(() => {
  const [location, setLocation] = useLocation();
  const query = qs.parse(window.location.hash);
  const user = jwtDecode(query.id_token as string) as User;

  if (user) {
    state.username = user["cognito:username"];
    setLocation("/", { replace: true });
  } else {
    setLocation("/logout", { replace: true });
  }

  return null;
});

export default AuthCallback;
