import { store } from "z-preact-easy-state";

type State = {
  username: null | string;
};

const state = store({
  username: null,
} as State);

export default state;
