import { FunctionalComponent, h } from "preact";
import { Route } from "wouter-preact";
import { view } from "z-preact-easy-state";
import state from "../store";
import AuthCallback from "./AuthCallback";
import Logout from "./logout";
// https://arxfg6uoo.auth.eu-west-1.amazoncognito.com/login?client_id=ojt7a1c4ests86thkktoupjji&response_type=token&scope=email+openid&redirect_uri=https://panu.ninja/auth/callback

const App: FunctionalComponent = view(() => {
  console.log("render App");

  return (
    <div id="app">
      Hello {state.username}
      <a
        href={`https://arxfg6uoo.auth.eu-west-1.amazoncognito.com/login?client_id=ojt7a1c4ests86thkktoupjji&response_type=token&scope=email+openid&redirect_uri=${window.location.origin}/auth/callback`}
      >
        login
      </a>
      <Route path="/logout" component={Logout} />
      <Route path="/auth/callback" component={AuthCallback} />
    </div>
  );
});

export default App;
