import { FunctionalComponent, h } from "preact";
import { view } from "z-preact-easy-state";
import qs from "query-string";

const Logout: FunctionalComponent = view(() => {
  const query = qs.parse(window.location.hash);
  console.log(query);
  return (
    <div className="container logout">
      <h1>Logged out</h1>
    </div>
  );
});

export default Logout;
